import { Menu } from "semantic-ui-react";
import { useNavigate, useLocation } from 'react-router-dom';

function TopMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <nav>
      <Menu pointing secondary>
        <Menu.Menu position="right">
          <Menu.Item name="特徴" active={location.pathname === "/"} onClick={() => navigate("/")} />
          <Menu.Item name="使い方" active={location.pathname === "/usage"} onClick={() => navigate("/usage")} />
          <Menu.Item name="お問い合わせ" active={location.pathname === "/contact"} onClick={() => navigate("/contact")} />
        </Menu.Menu>
      </Menu>
    </nav>
  );
}

export default TopMenu;