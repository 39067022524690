import React from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom"

import './App.css';
import TopPage from "./TopPage";
import UsagePage from "./UsagePage";
import ContactPage from "./ContactPage";

export function getApiPath(path) {
  return `/api/${path}`;
};

function App() {
  return (
    <div class="ui container">
      <Router>
        <Routes>
          <Route 
            path="/contact"
            element={<ContactPage />}
          />
          <Route 
            path="/usage"
            element={<UsagePage />}
          />
          <Route 
            path="/"
            element={<TopPage />}
          />
          <Route 
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
