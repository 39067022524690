import TopMenu from './TopMenu';

function UsagePage() {
    return (
        <div>
            <TopMenu />
            <h2>SilkyPath 利用イメージ解説動画</h2>
            <p>SilkyPathの基本的な使い方や特徴を、予約・受付・診察・会計の診療ワークフローに沿って解説します。</p>

            <div class="ui stackable two column grid">
                <div class="column">
                    <h3>予約</h3>
                    <p>患者様本人が、Web予約から診療メニューを選択して予約します。患者様から電話を受けたスタッフが、患者様に代わって予約取りすることもできます。</p>
                </div>
                <div class="center aligned column">
                    <iframe width="280" height="160" src="https://www.youtube.com/embed/AqiILXYDaXI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>

            <div class="ui stackable two column grid">
                <div class="column">
                    <h3>受付</h3>
                    <p>患者様本人が、自動受付機でチェックインします。受付スタッフが窓口対応する場合は、iPadを操作します。受付スタッフは、受付完了後に持ち場を離れることなく、オンラインで患者様を検査・診察へ引き継ぎます。</p>
                </div>
                <div class="center aligned column">
                    <iframe width="280" height="160" src="https://www.youtube.com/embed/wIG__9G9Qoo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>

            <div class="ui stackable two column grid">
                <div class="column">
                    <h3>検査</h3>
                    <p>iPhoneから、次の患者様を確認してお呼びします。検査中は、チェックリスト、申し送り、タイマー機能を活用して、作業漏れや伝達ミスを予防します。</p>
                </div>
                <div class="center aligned column">
                    <iframe width="280" height="160" src="https://www.youtube.com/embed/CGv788tVxAM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>

            <div class="ui stackable two column grid">
                <div class="column">
                    <h3>診察</h3>
                    <p>待合モニターから、次の患者様を自動音声で呼び出します。追加の検査オーダーが必要な場合は、追加タスク機能を使って検査担当へ指示します。診察終了時に、次回の診療内容（クリパス）と予約日の目安を登録します。</p>
                </div>
                <div class="center aligned column">
                    <iframe width="280" height="160" src="https://www.youtube.com/embed/LPTdV3mtsw8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>

            <div class="ui stackable two column grid">
                <div class="column">
                    <h3>手術</h3>
                    <p>オペ当日を起点に、術前・術後を含む一連の来院をまとめて日程取りします。オペ当日の来院時刻の決定を先送りする場合は、後日メールやSMSなどでお伝えできます。</p>
                </div>
                <div class="center aligned column">
                    <iframe width="280" height="160" src="https://www.youtube.com/embed/qUY09CwTHzw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>

            <div class="ui stackable two column grid">
                <div class="column">
                    <h3>次回予約</h3>
                    <p>会計待ちの間に、患者様本人が、院内予約機のタッチパネル操作により、次回の来院予約をお取りいただけます。</p>
                </div>
                <div class="center aligned column">
                    <iframe width="280" height="160" src="https://www.youtube.com/embed/jlfp9w_BHV8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>

            <div class="ui stackable two column grid">
                <div class="column">
                    <h3>会計</h3>
                    <p>患者様本人が、セルフレジのタッチパネル操作により、お薬確認、次回予約、お支払の３ステップを順々に実施します。保険と自費の両方をまとめてお支払いいただけます。</p>
                </div>
                <div class="center aligned column">
                    <iframe width="280" height="160" src="https://www.youtube.com/embed/ysN5iH8qdcI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>

            <div class="ui stackable two column grid">
                <div class="column">
                    <h3>TODO</h3>
                    <p>コンタクトレンズ発注や紹介状作成など、患者様のお帰り後に実施する作業を、TODOタスクにより漏れなく管理します。</p>
                </div>
                <div class="center aligned column">
                    <iframe width="280" height="160" src="https://www.youtube.com/embed/gsv00MBf1Dk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>

            <div class="ui stackable two column grid">
                <div class="column">
                    <h3>予約枠管理</h3>
                    <p>来院実績や待ち時間の統計に基づいて、定期的に予約枠をチューニングします。</p>
                </div>
                <div class="center aligned column">
                    <iframe width="280" height="160" src="https://www.youtube.com/embed/JZGYCtMSCjM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    );
}

export default UsagePage;