import React, { useState } from 'react'
import { Button, Form, Message, Table, Label, Input, TextArea } from 'semantic-ui-react'
import { getApiPath } from './App'
import TopMenu from './TopMenu';

function FormFieldLabel({ title, required }) {
  return (
    <label>
      <span>{title}</span>
      {required && <Label size='small' color='red'>必須</Label>}
    </label>
  );
}

function ContactPage() {
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState("input"); // input, confirm, submit

  const [error, setError] = useState();
  const [name, setName] = useState("");
  const [kana, setKana] = useState("");
  const [clinic, setClinic] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [message, setMessage] = useState("");

  const checkRequired = (title, value) => {
    if (value.length <= 0) {
      setError(`${title}を入力してください`);
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const checkEmail = (title, value) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(value.toLowerCase())) {
      return true;
    } else {
      setError(`${title}の形式に誤りがあります`);
      return false;
    }
  }

  const validateInput = () => {
    return checkRequired("お名前", name)
      && checkRequired("フリガナ", kana)
      && checkRequired("医療機関名", clinic)
      && checkRequired("メールアドレス", email)
      && checkEmail("メールアドレス", email)
      && checkRequired("電話番号", tel)
  };

  const submitInput = () => {
    setLoading(true);
    fetch(getApiPath("messages/submit"), {
      method: "POST",
      headers: new Headers({ 'Content-Type': `application/json` }),
      body: JSON.stringify({ name: name, kana: kana, clinic: clinic, email: email, tel: tel, message: message })
    })
      .then(
        res => {
          setLoading(false);
          if (res.status === 204) {
            setError(null);
            setPage("submit");
          } else {
            setError(`[${res.status}] システムエラーにより、処理を完了できませんでした。`);
          }
        },
        e => {
          setLoading(false);
          setError("システムに接続できませんでした。ネットワークに問題がないか確認してください。");
        }
      );
  };

  return (
    <div>
      <TopMenu />
      <h2>お問い合わせフォーム</h2>
      <div>
        {page === "input" &&
          <div>
            <p>
              SilkyPathにご興味をお持ちいただき、誠にありがとうございます。
            </p>
            <p>
              デモ・見積りなどのご依頼やご質問は、下記フォームからお寄せください。<br />
              通常、２営業日以内にご返信いたします。
            </p>
            <div class="ui raised segment">
              <Form error={error} onSubmit={() => validateInput() && setPage("confirm")} >
                <Form.Field>
                  <FormFieldLabel title="お名前" required />
                  <Input
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <FormFieldLabel title="フリガナ" required />
                  <Input
                    required
                    value={kana}
                    onChange={(e) => setKana(e.target.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <FormFieldLabel title="医療機関名" required />
                  <Input
                    required
                    value={clinic}
                    onChange={(e) => setClinic(e.target.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <FormFieldLabel title="メールアドレス" required />
                  <Input
                    type='email'
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <FormFieldLabel title="電話番号" required />
                  <Input
                    type='tel'
                    required
                    value={tel}
                    onChange={(e) => setTel(e.target.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <FormFieldLabel title="お問い合わせ内容" />
                  <TextArea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Form.Field>
                <Message
                  error
                  content={error}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p>ご記入いただいた個人情報につきましては、お問い合わせの回答以外の目的には使用いたしません。</p>
                <div class="ui center aligned basic segment">
                  <Form.Button type='submit'>確認</Form.Button>
                </div>
              </Form>
            </div>
          </div>
        }
        {page === "confirm" &&
          <div>
            <p>以下の内容で予約します。よろしいですか？</p>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>お名前</Table.Cell>
                  <Table.Cell>{name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>フリガナ</Table.Cell>
                  <Table.Cell>{kana}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>医療機関名</Table.Cell>
                  <Table.Cell>{clinic}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>メールアドレス</Table.Cell>
                  <Table.Cell>{email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>電話番号</Table.Cell>
                  <Table.Cell>{tel}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>お問い合わせ内容</Table.Cell>
                  <Table.Cell><pre>{message}</pre></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            {error &&
              <Message
                error
                content={error}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            }
            <div class="ui center aligned basic segment">
              <Button onClick={() => {setError(null); setPage("input"); }}>やり直し</Button>
              <Button positive onClick={submitInput} loading={loading}>送信</Button>
            </div>
          </div>
        }
        {page === "submit" &&
          <div>
            <p>
              お問い合わせを受付いたしました。<br />
              通常、２営業日以内にご返信いたします。
            </p>
          </div>
        }
      </div>
    </div>
  );
}

export default ContactPage;