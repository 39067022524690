import TopMenu from './TopMenu';

function TopPage() {
  return (
    <div>
        <TopMenu />
        <section class="ui center segment basic">
            <h1>
                <img class="ui mini right spaced image" src="images/app_icon.png"  />
                <span>SilkyPath</span>
                <span style={{"fontSize": "1.0rem"}}>（シルキーパス）</span>
            </h1>
            <em style={{"fontSize": "large"}}>〜 シンプルな診療予約システムで満足ですか？ 〜</em>
        </section>

        <section class="ui segment basic">
            <div class="ui stackable two column grid">
                <div class="column">
                    <p>
                        クリニックに完全予約制を導入すると、少ない待ち時間による患者様の満足度向上と、診療業務の平準化によるスタッフの働きやすさ向上を実現できます。
                        しかし、患者様に予約のひと手間を強いることや、予約に対応する事務負担の増加がデメリットとしてあるため、多くのクリニックが導入に踏み切れていません。
                    </p>
                    <p>
                        市場には、完全予約制のデメリットを軽減する解決策として、優に３０を超える診療予約・受付システムが販売されています。
                        しかし、その多くが「操作が簡単でリーズナブルな料金」をウリとするシンプルなシステムで、中規模クリニック（スタッフ数１０名以上）が抱えている複雑なニーズを受け止められていません。
                    </p>
                    <p>
                        SilkyPathは、このギャップを埋めるために誕生した、中規模クリニック向けの診療予約・受付・精算システムです。
                    </p>
                </div>
                <div class="column">
                    <p>
                        <img class="ui image" src="images/appointment_matrix.png" />
                    </p>
                </div>
            </div>
        </section>
        <section class="ui segment basic">
            <h2>
                <img class="ui mini right spaced image" src="images/schedule_icon.png"  />
                <span class="headline">高度な予約枠管理</span>
            </h2>

            <h3>作成できる予約枠の種類・枠数は上限なし</h3>
            <div class="ui stackable two column grid">
                <div class="column">
                    <p>
                        複数の医師が幅広い治療メニューを提供している中規模クリニックでは、予約システムで管理すべき予約枠の種類・枠数が多いため、予約表が巨大化する傾向にあります。
                    </p>
                    <p>
                        SilkyPathは、予約枠の種類・枠数を上限なく増やせますので、クリニックが提供している治療メニューの全てを一元管理できます。1日あたり50種類・300枠以上の複雑な予約枠を運用している実績があります。
                    </p>
                </div>
                <div class="column">
                    <img class="ui image" src="images/many_item.png"  />
                </div>
            </div>

            <h3>OR予約枠（排他予約枠）</h3>
            <div class="ui stackable two column grid">
                <div class="column">
                    <p>
                        スタッフの勤務シフト、検査機器の台数、タスクの所要時間などのリソース制約により、種類の異なる予約が同じ時間帯に重なると都合の悪い場合があります。
                    </p>
                    <p>
                        SilkyPathのOR予約枠を使用すると、排他グルーピングされた予約枠のどれかが予約取りされたタイミングで、同じ時間帯の残りの予約枠を自動的にブロックします。
                    </p>
                </div>
                <div class="column">
                    <img class="ui image" src="images/or.png" />
                </div>
            </div>

            <h3>連鎖予約（オペ日程取り）</h3>
            <div class="ui stackable two column grid">
                <div class="column">
                    <p>
                        日帰り手術の予約は、当日に加えて術前・術後まで含めたワンセットで予約取りするのが一般的です。術前・術後の日時はいつでも良いわけではなく、術式毎に望ましいタイミングが定められています。
                    </p>
                    <p>
                        その為、オペの日程取りを担当するスタッフは、患者様の都合を確認しながら術前・当日・術後のタイミングが揃う空き予約を見つけなければならず、大変な労力がかかります。
                    </p>
                    <p>
                        SilkyPathは、術前・当日・術後のタイミングが揃っている候補日のセットを、大量の予約枠の中から自動的に抽出できます。
                        スタッフに必要な操作は、自動抽出されたリストの中から、患者様の都合とマッチするものを選択するだけです。
                    </p>
                    <p>
                        オペ日程表印刷機能を使えば、予約取りした内容を印刷して、そのまま患者様へお渡しできます。
                    </p>
                </div>
                <div class="column">
                    <img class="ui image" src="images/ope_schedule.png" />
                </div>
            </div>

            <h3>PDCAサイクルによる予約枠のブラッシュアップ</h3>
            <div class="ui stackable two column grid">
                <div class="column">
                    <p>
                        予約枠は、一度決めたら終わりではありません。
                        枠の埋まり具合、患者様の平均待ち時間、スタッフの残業時間などの実績を振り返って、定期的にブラッシュアップする必要があります。
                    </p>
                    <p>
                        一般的な受付システムが在院時間（受付〜会計までの長さ）しか記録していないのと対照的に、SilkyPathは、受付・検査・診察などの各セクションが対応した時間を個別に記録しています。
                    </p>
                </div>
                <div class="column">
                    <img class="ui image" src="images/timeline.png" />
                </div>
            </div>
            <div class="ui stackable two column grid">
                <div class="column">
                    <p>
                        精密で定量的な記録の分析により、ボトルネックになっているセクションや、患者様の待ち時間削減の為に改善すべきポイントなどを容易に発見することができます。
                    </p>
                    <p>
                        この気づきを活かして、予約枠や勤務シフトなどの業務計画をより効果的に立案できるようになります。
                    </p>
                </div>
                <div class="column">
                    <img class="ui image" src="images/pdca.png" />
                </div>
            </div>

            <h3>治療メニュー別にカスタマイズ可能なリマインド</h3>
            <div class="ui stackable one column grid">
                <div class="column">
                    <p>
                        ほとんどの予約システムが、予約前日に患者様へ来院日時をメール送信するリマインド機能を備えています。
                    </p>
                    <p>
                        SilkyPathでは、予約の種類（治療メニュー）別に、リマインドメッセージの内容と送信タイミングをカスタマイズできます。
                    </p>
                    <p>
                        例えば、来院の３日前から患者様の事前準備が必要な治療の場合、３日前に準備内容をお伝えするメールを送り、来院前日に来院時間をお伝えするメールを送るという、２段階のきめ細いリマインドが実現できます。
                    </p>
                    <p>
                        Eメールの他にSMSにも対応していますので、幅広い年齢層の患者様にご利用いただけます。
                    </p>
                </div>
            </div>

            <h3>キャンセル待ち</h3>
            <div class="ui stackable one column grid">
                <div class="column">
                    <p>
                        人気の治療メニューは予約枠がすぐ埋まってしまうため、多くの患者様が希望する日付で予約できない不満を抱えています。
                    </p>
                    <p>
                        この不満の軽減策として、SilkyPathはキャンセル待ち機能を提供しています。
                        キャンセル待ちにエントリーした患者様は、キャンセル発生により予約枠が空いたことを、メール通知でリアルタイムに知ることができます。
                    </p>
                    <p>
                        この仕組みにより、患者様の不満を軽減しつつ、キャンセルによるクリニックの機会損失を予防できます。
                    </p>
                </div>
            </div>
        </section>
        <section class="ui segment basic">
            <h2>
                <img class="ui mini right spaced image" src="images/smartphone_icon.png" />
                スマホの手軽さで
            </h2>

            <div class="ui stackable two column grid">
                <div class="column">
                    <p>
                        パソコン操作を前提としている従来のシステムと異なり、SilkyPathは、iPad・iPhoneから操作します。
                    </p>
                    <p>
                        パソコンよりも省スペースで安価なので、必要十分な数の端末を設置できます。
                    </p>
                    <p>
                        患者様の呼び出し、次の担当セクションへの回覧や申し送りなどの為に、持ち場を離れる必要はありません。
                        これらの作業は、手元のiPad・iPhoneからその場で完遂できるため、移動のロスなく効率的に診療を進められます。
                    </p>
                </div>
                <div class="column">
                    <img class="ui image" src="images/intersection.png" />
                </div>
            </div>

        </section>
        <section class="ui segment basic">
            <h2>
                <img class="ui mini right spaced image" src="images/selfservice_icon.png" />
                診療プロセス全体をセルフ化
            </h2>

            <div class="ui stackable two column grid">
                <div class="column">
                    <p>
                        SilkyPathは、予約から会計に至る診療プロセス全体をシームレスに統合することで、徹底したセルフ化を実現しています。
                    </p>
                    <ol class="ui list">
                        <li>
                            <h4>Web予約</h4>
                            <p>特殊な検査や医師指定のある予約リクエストなども、スタッフが電話対応することなくWeb完結で予約取りできます。</p>
                        </li>
                        <li>
                            <h4>自動受付機</h4>
                            <p>
                                自費・保険どちらの予約にも対応しており、治療メニュー毎に、自動受付機と窓口対応の振り分けを設定できます。
                            </p>
                            <p>
                                保険診療の場合は、オンライン資格確認とシステム連携して保険証の有効性を確認します。
                            </p>
                        </li>
                        <li>
                            <h4>待合モニター</h4>
                            <p>診察室のみならず、検査室や会計窓口など、複数の場所に設置できます。</p>
                        </li>
                        <li>
                            <h4>院内予約機</h4>
                            <p>診察後の会計待ちの間に、患者様本人の操作のみで次回の予約取りができます。</p>
                        </li>
                        <li>
                            <h4>セルフレジ</h4>
                            <p>
                                会計の一般的なやりとりである「院内処方薬の確認」→「次回予約」→「精算」の３ステップを、患者様本人の操作のみで進めることができます。
                            </p>
                            <p>
                                自費・保険のどちらの精算にも対応しています。保険診療の精算と院内処方薬の確認は、ORCAとシステム連携しています。
                            </p>
                        </li>
                    </ol>
                </div>
                <div class="column">
                    <img class="ui image" src="images/whole_process.png" />
                </div>
            </div>
        </section>
        <footer class="ui center aligned grey inverted basic segment">
            <div class="content has-text-centered">
                <span>&copy;2022 スパイラルウェア株式会社</span>
            </div>
        </footer>
    </div>
  );
}

export default TopPage;
